<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getAllDocumentsUploaded()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-file-document-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Documents
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getServiceProviderActivities()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-transit-transfer
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Activities
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isOrgInvoiceEnabled"
            link
            @click="getServiceProviderInvoices()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-receipt-text-check
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Invoices
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-account-check
                </v-icon>
              </v-list-item-icon>
              Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getAllDocumentsUploaded()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-file-document-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Documents
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getServiceProviderActivities()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-transit-transfer
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Activities
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isOrgInvoiceEnabled"
            link
            @click="getServiceProviderInvoices()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-receipt-text-check
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Invoices
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <service-provider-documents v-if="showDocumentsUploaded" />
    <service-provider-activities v-if="showActivities" />
    <service-provider-invoices v-if="showServiceProviderInvoices" />
  </v-container>
</template>

<script>
  import ServiceProviderDocuments from 'src/views/dashboard/ServiceProviders/ServiceProviderDocuments.vue';
  import ServiceProviderActivities from 'src/views/dashboard/ServiceProviders/ServiceProviderActivities.vue';
  import ServiceProviderInvoices from 'src/views/dashboard/ServiceProviders/ServiceProviderInvoices.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';

  export default {
    name: 'ServiceProviderDetailsLandingPage',
    components: {
      'service-provider-activities': ServiceProviderActivities,
      'service-provider-invoices': ServiceProviderInvoices,
      'service-provider-documents': ServiceProviderDocuments,
    },
    data: () => ({
      showDocumentsUploaded: true,
      selectedItem: -1,
      showActivities: false,
      showServiceProviderInvoices: false,
      helper: new ModuleHelper(),
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      isOrgInvoiceEnabled () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      back () {
        this.$router.push({ name: 'Service Providers' });
      },
      getAllDocumentsUploaded () {
        this.showServiceProviderInvoices = false;
        this.showActivities = false;
        this.showDocumentsUploaded = true;
        },
      getServiceProviderActivities () {
        this.showDocumentsUploaded = false;
        this.showServiceProviderInvoices = false;
        this.showActivities = true;
      },
      getServiceProviderInvoices () {
        this.showDocumentsUploaded = false;
        this.showActivities = false;
        this.showServiceProviderInvoices = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
</style>
