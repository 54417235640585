<template>
  <div>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="userActivities.headers"
        :items="AllServiceProviderActivities"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        disable-sort
        light
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td class="type-style">
              {{ item.type }}
            </td>
            <td>
              <font v-if="item.job_details !== null">
                {{ item.job_details.job_number }}
              </font> <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.site.name }}</td>
            <td>{{ item.checkin_time | convertToLocal }}</td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Active*
              </font><font v-else>
                {{ item.checkout_time | convertToLocal }}
              </font>
            </td>
            <td>
              <font
                v-if="item.job_details !== null"
                class="job-status-style"
                :color="getStatusColor(item.job_details.status)"
              >
                {{ item.job_details.status }}
              </font>
              <font
                v-else-if="item.visitor_record !== null"
                class="job-status-style"
                :color="getStatusColor(item.visitor_record.status)"
              >
                {{ item.visitor_record.status }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded), isStaffSelected(item.type)"
              >
                <v-icon
                  v-if="!isExpanded"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
          >
            <v-row>
              <v-col
                v-if="item.job_details !== null"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-In Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Description: {{ item.job_details.description }}
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              High Risk Work: <font
                                :color="getHrwColor(item.job_details.high_risk)"
                                class="my-hrw-style"
                              >{{ item.job_details.high_risk | displayHighRisk }}</font>
                            </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.visitor_record !== null"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-In Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Visit Purpose: {{ item.visitor_record.purpose }}
                            </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.job_details !== null"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-Out Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title
                            v-if="isOrgInvoiceEnabled"
                            class="my-style"
                          >
                            <strong class="status-font-style">
                              Invoice:
                              <font v-if="item.job_details.invoice !== null">
                                {{ item.job_details.invoice }}
                              </font>
                              <font v-else>
                                N/A
                              </font>
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Status: <font
                                class="status-font-style"
                                :color="getStatusColor(item.job_details.status)"
                              >{{ item.job_details.status }}<font /></font></strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Pending Reason: <font v-if="item.job_details.pending_reason !== null"> {{ item.job_details.pending_reason }}</font>
                              <font v-else> N/A </font>
                            </strong>
                          </v-list-item-title>
                          <br>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.visitor_record !== null"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-Out Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Status: <font
                                class="status-font-style"
                                :color="getStatusColor(item.visitor_record.status)"
                              >{{ item.visitor_record.status }}<font /></font></strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Pending Reason: <font
                                v-if="item.visitor_record.pending_reason !== null"
                                class="status-font-style"
                              > {{ item.visitor_record.pending_reason }}</font>
                              <font
                                v-else
                                class="status-font-style"
                              > N/A </font>
                            </strong>
                          </v-list-item-title>
                          <br>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';

  export default {
    name: 'AllServiceProviderActivities',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      displayHighRisk (status) {
        if (status === true) {
          return 'Yes*';
        } else {
          return 'No';
        }
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        userActivities: {
          headers: [
          { text: 'Type', align: 'start', value: 'type' },
          { text: 'Job Number', value: 'job_number' },
          { text: 'Location', value: 'name' },
          { text: 'Check-In Time', value: 'checkin_time' },
          { text: 'Check-out Time', value: 'checkout_time' },
          { text: 'Status', value: 'status' },
          { text: 'More', value: 'data-table-expand' },
        ],
        },
        expanded: [],
        singleExpand: true,
        helper: new ModuleHelper(),
      };
    },
    computed: {
      AllServiceProviderActivities () {
        const list = this.$store.getters['serviceproviders/getAllServiceProviderActivities'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
      isOrgInvoiceEnabled () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
      },
    },
    async mounted () {
      await this.fetchAllServiceProviderActivities();
    },
    methods: {
      async fetchAllServiceProviderActivities () {
        this.loading = true;
        await this.$store.dispatch('serviceproviders/fetchAllServiceProviderActivities', {
          serviceProviderId: this.$route.params.externalId,
        });
        this.loading = false;
      },
      getStatusColor (status) {
        if (status === 'completed') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      isStaffSelected (type) {
        if (type === 'staff') {
          this.$store.dispatch('alert/onAlert', {
              message: 'No details to display.',
              type: Constants.ALERT_TYPE_INFO,
         });
        }
      },
      getHrwColor (value) {
        if (value === true) return '#C62828';
        else { return '#2E7D32'; }
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.type-style {
  text-transform: capitalize;
  font-weight: bold;
}
.my-style {
  margin-bottom: 10px !important;
}
.my-hrw-style {
  text-transform: capitalize;
  font-weight: bold;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-font-style {
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  font-weight: bold;
}
.job-status-style {
  text-transform: capitalize;
  font-weight: bold;
}
</style>
