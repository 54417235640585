<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-file-document-outline
      </v-icon> Documents
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items=" serviceProviderDocuments"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.document_type.name }}</td>
            <td>{{ item.document_type.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>{{ item.expiry_date | convertStringToLocalDatetime }}</td>
            <td>
              <font
                class="status-style"
                :color="getStatusColor(item.is_active)"
              >
                {{ item.is_active | status }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showDocument(item.id, item.original_file_name)"
              >
                <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'ServiceProviderDocuments',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertStringToLocalDatetime (val) {
        return moment(val).local().format('Do MMMM YYYY');
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      status (val) {
        if (val === true) {
          return 'Active*';
        }
        return 'Expired';
      },
    },
    data () {
      return {
        search: '',
        loading: false,
          headers: [
            { text: 'Document Name', align: 'start', value: 'document_name' },
            { text: 'Description', value: 'last_name' },
            { text: 'Uploaded On', value: 'created_at' },
            { text: 'Expiry Date', value: 'expiry_date' },
            { text: 'Status', value: 'status' },
            { text: 'Download', value: 'download' },
          ],
      };
    },
    computed: {
      serviceProviderDocuments () {
        return this.$store.getters['serviceproviders/getServiceProviderDocuments'];
      },
    },
    async mounted () {
      await this.getServiceProviderDocuments();
    },
    methods: {
      async getServiceProviderDocuments () {
        this.loading = true;
        await this.$store.dispatch('serviceproviders/fetchServiceProviderDocuments', {
          serviceProviderId: this.$route.params.externalId,
        });
        this.loading = false;
      },
      showDocument (documentTypeId, fileName) {
        this.loading = true;
        this.$store.dispatch('serviceproviders/dowloadDocment', {
          documentId: documentTypeId,
          fileName: fileName,
        });
        this.loading = false;
      },
      getStatusColor (val) {
        if (val === true) {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.status-style {
  font-weight: bold;
}
</style>
