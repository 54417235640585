<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-receipt-text-check
      </v-icon> Invoices
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items=" serviceProviderInvoices"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <span v-if="item.job_details !== null">
                {{ item.job_details.job_number }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.site.name }}</td>
            <td>{{ item.checkin_time | convertStringToLocalDatetime }}</td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Active*
              </font><font v-else>
                {{ item.checkout_time | convertStringToLocalDatetime }}
              </font>
            </td>
            <td>{{ item.total_hours }}</td>
            <td>
              <font
                :color="getColor(item.job_details.status)"
                class="font-my-style"
              >
                {{ item.job_details.status }}
              </font>
            </td>
            <td>
              <v-chip
                outlined
                class="ml-2"
                color="#37474F"
                dark
              >
                {{ item.job_details.invoice }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';

  export default {
    name: 'ServiceProviderInvoices',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Job Number', align: 'start', value: 'job_number' },
          { text: 'Location', value: 'name' },
          { text: 'Check In Time', value: 'checkin_time' },
          { text: 'Check Out Time', value: 'checkout_time' },
          { text: 'Total Hours', value: 'total_hours' },
          { text: 'Status', value: 'status' },
          { text: 'Invoice Amount', value: 'invoice' },
        ],
        helper: new ModuleHelper(),
      };
    },
    computed: {
      serviceProviderInvoices () {
        const list = this.$store.getters['serviceproviders/getServiceProviderInvoices'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
      isOrgInvoiceEnabled () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
      },
    },
    async mounted () {
      this.isOrgInvoiceEnabled && await this.getServiceProviderInvoices();
    },
    methods: {
      async getServiceProviderInvoices () {
        this.loading = true;
        const serviceProviderId = this.$route.params.externalId;
        await this.$store.dispatch('serviceproviders/fetchServiceProviderInvoices', {
          serviceProviderId: serviceProviderId,
        });
        this.loading = false;
      },
      getColor (status) {
        if (status === 'completed') return '#2E7D32';
        else { return '#C62828'; }
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}
</style>
